import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from '../../assets/Tomas_Utaravicius_Logo_Black.png'

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
        fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100pt" height="100pt"  className={classes.svgHover} viewBox="0 0 500 500"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(35.000000,345.000000) scale(0.0600000,-0.0600000)">
<path d="M1337 4545 c-659 -315 -1208 -582 -1219 -592 -12 -10 -29 -36 -39
-58 -18 -38 -19 -103 -19 -1446 0 -1109 3 -1409 13 -1422 16 -23 844 -367 883
-367 58 0 90 75 50 119 -9 10 -163 79 -342 152 -213 88 -321 137 -312 142 7 5
507 212 1111 462 l1098 453 1102 -455 c606 -250 1105 -458 1109 -462 4 -4
-492 -213 -1102 -465 l-1109 -458 -478 197 c-263 109 -547 226 -632 261 -84
35 -165 64 -180 64 -57 0 -91 -75 -54 -118 11 -12 286 -131 653 -283 565 -234
641 -263 690 -263 50 0 161 44 1210 478 1326 549 1268 524 1280 547 6 11 10
553 10 1419 0 1504 2 1438 -48 1493 -27 31 -596 307 -632 307 -9 0 -29 -9 -44
-21 -34 -27 -36 -77 -3 -105 12 -11 144 -78 292 -148 149 -71 273 -131 278
-135 4 -4 6 -604 5 -1333 l-3 -1326 -1130 467 -1130 467 -3 1402 c-1 771 0
1402 2 1402 2 0 320 -151 706 -335 385 -184 709 -335 719 -335 10 0 30 9 45
21 34 27 36 77 4 106 -32 29 -1535 743 -1561 742 -12 0 -561 -259 -1220 -574z
m1141 -1027 l-3 -1403 -1130 -467 -1130 -466 -3 1326 c-1 729 1 1329 5 1333 5
3 271 132 593 285 321 154 825 394 1120 536 294 141 538 257 543 257 4 1 6
-630 5 -1401z"/>
</g>
</svg>
    );
};

/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";

import './About.css';

import profile from '../../assets/tu_profile_2.png';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
    textAlign: 'left', // Center text within the container
  },
  contentWrapper: {
    maxWidth: '80%',
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = (
    <div>
      <p className="paragraph" style={{ fontSize: '1.5rem' }}>
        I am {FirstName} {LastName}, a seasoned Game Developer with a passion for
        crafting immersive experiences and games using cutting-edge 3D
        technologies.
        My deep-rooted love for games is matched only by my enthusiasm for the
        creative journey behind each project.
        With a wealth of experience spanning mobile and PC game development, as
        well as a profound understanding of VR and AR applications, I bring a
        versatile skill set to every endeavor.
        My expertise lies in the intricate design and seamless implementation of
        gameplay systems, all while prioritizing optimization to ensure the best
        possible player experience.
      </p>

      <p className="paragraph" style={{ fontSize: '1.5rem' }}>
        My track record includes successfully publishing games on prominent
        platforms such as Google Play, the App Store, the Meta store, and Steam.
        My journey in the world of technology extends beyond gaming, as I have
        made significant contributions to the fields of Architectural
        Visualization, Education, and Cultural projects, enriching my knowledge
        and versatility.
        Additionally, I am well-versed in front-end development practices using
        React, and I have a robust command of cloud services, including Azure and
        Firebase.
      </p>
      <p className="paragraph" style={{ fontSize: '1.5rem' }}>
        I am perpetually excited to tackle new and exciting challenges, pushing
        the boundaries of what's possible in the realm of interactive experiences.
      </p>
    </div>
  );
  return (
    <section id="about">
      <Container component="main" className={classes.main}>
        <div className={classes.contentWrapper}>
          <Typography component='h2' variant="h5">
            <TextDecrypt text={`${greetings}`} />
          </Typography>
          <p className="aboutme">
            {aboutme}
          </p>
        </div>
      </Container>
    </section>
  );
};

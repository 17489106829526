import React, { useState, useContext } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import { ThemeContext } from "../theme/ThemeProvider";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useMediaQuery } from "@material-ui/core";
import './Works.css';
const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "30vh",
    marginBottom: "15vh",
    width: "90%",
    justifyContent: "center", /* Center vertically */
    alignItems: "center", /* Center horizontally */
    textAlign: "center" /* Center text within .contact if needed */
  },
  projectGrid: {
    justifyContent: "center",
  },
  projectItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
    height: "100%",
    position: "relative",
    transition: "transform 0.5s ease, box-shadow 0.5s ease",
    transform: 'scale(1.00)', // Scale up the paper on hover
    boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.5)", // Add a shadow to each grid item
    borderRadius: "20px", // Rounded corners for the grid item
  },
  projectItemHover: {
    '&:hover': {
      transition: "transform 0.5s ease, box-shadow 0.5s ease",
      transform: 'scale(1.01)', // Scale up the paper on hover
      boxShadow: '0px 0px 35px rgba(0, 0, 0, 1.0)', // Increase shadow on hover
    },
  },
  projectImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    boxShadow: "0px 0px 12px rgba(0, 0, 0, 1)", // Add a shadow to each grid item
    borderRadius: "3px", // Rounded corners for the image
  },
  projectTitle: {
    position: "absolute",
    zIndex: 1,
    padding: theme.spacing(2),
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "5px", // Slightly rounded corners for the title
    top: "20px", // Position title higher in the grid item
  },
}));


export const Works = ({ fullscreen }) => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();
  const isTabletOrLarger = useMediaQuery("(min-width: 768px)");
  const [projects,setProjects] = useState([
    {
      title: "Naturpark Lillebaelt VR",
      width: 9,
      height: 3, 
      cover_image:"https://i.ibb.co/xKk2Xv3/coverart2560x1440.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/t2fZZgQ/screenshot1.png" draggable="false">
        </img>
        <p className="content-text">Immerse yourself in Naturpark Lillebælt's captivating VR game, where the ocean's wonders spring to life! Explore Denmark's Lillebælt, partaking in thrilling missions for marine restoration and conservation. From planting eelgrass to underwater photography, clean-up efforts, and robotic fish capturing, this game combines fun, learning, and sustainability.
        This educational VR experience, in collaboration with Naturpark Lillebælt, educates players about regional biodiversity and sustainability. Dive in and become an ocean steward, gaining a deep appreciation for conservation and your role in preserving this precious ecosystem.
        Don your virtual snorkel for an educational and entertaining adventure, as you contribute to restoring Lillebælt's biodiversity. Join Naturpark Lillebælt's VR game for an incredible journey into the ocean's enchanting world like never before.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text"> Gameplay systems and architecture </h3>
          <p className="content-text"> This game offers an immersive 20-30 minute VR experience filled with engaging missions and activities within the primary level. I was tasked with developing most of the gameplay systems to facilitate the completion of five exciting quests. For instance, in one mission, players embark on a breathtaking dive into the picturesque Lillebaelt to gather mussels, a task that involves seamlessly integrating multiple systems to captivate the player. </p>
          <h4 className="content-text"> Oxygen Depletion and Replenishment system </h4>
          <p className="content-text">As the player goes for a dive, their oxygen gradually decreases. If they use up all their oxygen, they'll have to restart the dive. But, don't worry! There's a special area near the mission's goal where players can refill their oxygen. I've also added animated effects to highlight moments of low oxygen, getting more air, and the consequences of running out of it. </p>
          <h4 className="content-text"> Swimming system and obstacles </h4>
          <p className="content-text"> I've created a straightforward physics-based swimming system for when the player is underwater. To swim, the player needs to use both hands to grab and pull the world in a direction, building up speed that naturally decreases because of water resistance. This swimming mechanic allows the player to maneuver through obstacles and face challenges like strong currents that try to pull them out to sea. </p>
          <h4 className="content-text"> Underwater navigation system.</h4>
          <p className="content-text"> I've implemented a basic path-following system that continuously checks the player's location and finds the nearest point on the path. This ensures that the navigation pointer remains reasonably close to the player at all times. </p>
          <h3 className="content-text"> Level design </h3>
          <img className="image-singular-full" 
          src="https://i.ibb.co/0yz2pwW/screenshot5.png" draggable="false">
          </img>
          <p className="content-text"> 
          In my integral role as the creative force behind crafting captivating underwater environments for our VR game, my primary focus rested on the creation of immersive settings that would not merely engage but thoroughly engulf players in a world of exploration and adventure. My mission was to transport players to the depths of the ocean, where the vast and mysterious underwater realm would come alive before their eyes.
          To achieve this, I dedicated meticulous attention to every detail, ensuring that each environment was seamlessly interconnected to offer a consistent and immersive experience. The underwater landscapes were painstakingly designed, with thoughtful placement of obstacles and the integration of interactive elements that would serve to enhance the overall gameplay.
          The goal was to immerse players so deeply in these environments that they would forget they were in a virtual world, allowing them to fully embrace the sense of wonder and adventure unique to the underwater domain. Through my work, I aimed to not only provide visually stunning backdrops but also to seamlessly weave gameplay elements into the very fabric of these underwater worlds, challenging players to think strategically and explore the mysteries that lay beneath the surface. </p>
          <h3 className="content-text"> User Experience </h3>
          <img className="image-singular-full" 
          src="https://i.ibb.co/0KZkpfW/screenshot4.png" draggable="false">
          </img>
          <p className="content-text"> To ensure a memorable and enjoyable experience, I dedicated considerable effort to implementing a distinct method of guiding players through tasks using particle effects and a range of animations. I repurposed the navigation pointer to indicate the next item requiring interaction, and I crafted various animations to provide clear cues, such as indicating the direction in which a wheel should be turned, and similar visual aids.
          </p>
          <h3 className="content-text"> Optimization </h3>
          <p className="content-text"> As we targeted the Quest 2, a mobile VR device, we faced stringent performance limitations. To manage this, I maintained a vigilant eye on the triangle count, regularly collaborating with the art team to optimize models and ensure they aligned with our triangle budget. Meeting the draw call budget proved especially challenging, as Quest 2 allows only 200 draw calls for smooth 72 fps performance.
          In response, I employed a custom camera culling technique to intelligently remove unnecessary meshes based on distance and camera angle, a necessity given the limitations. While using Unity's terrain system initially seemed promising, it severely impacted performance. Instead, I opted to convert the terrain into optimized meshes and automatically export vegetation as separate meshes, later combining them manually.
          Finding the right balance between draw calls and triangles was crucial. Given that players primarily explored the underwater dome at the center of the level, I consolidated all vegetation into approximately 20 meshes, arranging them in a ring around the dome. This strategic placement enabled the implementation of a straightforward camera culling mechanism, resulting in significant triangle count reductions, varying from 100k to 350k, depending on the player's position and viewpoint. </p>`
        },
        {
          title: "Find and Play",
          markdown: ` <iframe className="videoPlayer" src="https://www.youtube.com/embed/ZLDAaIYwtu4?si=5CZMrZbuLTwnBLnr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <h3 className="content-text"> Steam </h3>
          <p className="content-text"> You can find and play the game on PC by presssing <a className="content-text" href="https://store.steampowered.com/app/2569520/Naturpark_Lilleblt_VR/">Naturpark Lillebaelt VR on Steam!</a> </p>
          <h3 className="content-text"> Meta </h4>
          <p className="content-text"> The game is available for download on Meta's AppLab, which can be directly accessed through the Quest 2 device by searching for "Naturpark Lillebaelt VR". </p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "CricketWay",
      width: 3,
      height: 3, 
      cover_image:"https://i.ibb.co/Jdzz198/fotor-2023-9-21-18-16-5-fotor-20230921193423.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/7WVd1Dr/Cricket-Banner-V3.png" draggable="false">
        </img>
        <p className="content-text">CricketWay is a casual mobile cricket game that is currently in development for iOS and Android platforms. The game is built with Unity and offers an enjoyable and accessible experience for cricket fans of all levels. In  CricketWay, you play as a batsman for a variety of national teams and compete in both Single and Tournament game modes. The timing hit system adds a fun and competitive element to the gameplay, while the leaderboard allows players to compete with others and see where they rank. Players can also share their experiences and achievements on social media.The opposing team AI provides a balanced and challenging experience, making every game unique. The player's goal is to score as many runs as possible in a single game mode. During tournament, player is assigned a target of runs he or she needs to make within a limit of balls and wickets which are randomized. The game follows standard cricket rules, including overs, runs, wickets, and boundaries. Some of the rules are simplified to support choices made by the customer.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">Creating game mechanics</h3>
          <p className="content-text">In this project, I took charge of the implementation of the game mechanics. A crucial aspect of the game is the state machine-driven game loop. To achieve this, I utilized the state machine pattern to develop a modular state system that separated the game into two distinct elements: context and action. Context refers to the current game statistics, such as wickets, balls, runs, etc., while action represents the state-based behavior that will be performed next. Each state executes game logic by directing the AI, UI, and game management systems to perform specific actions. Another highlight of the project was the player hitting system. To determine the ideal moment for the player to hit the ball, I performed a physics simulation to calculate the timing and position of the optimal ball placement and displayed it on the UI.</p>
          <img className="image-singular-full" 
          src="https://i.ibb.co/dtk8H3n/Gameplay-1.png" draggable="false">
          </img>
          <h3 className="content-text">In-Game GUI Design and Implementation</h3>
          <img className="image-singular-full" 
          src="https://i.ibb.co/sgfNpq9/Physical-UI-2.png" draggable="false">
          </img>
          <p className="content-text">The game sets itself apart from the competition through its innovative physical UI, which takes inspiration from other games in the genre. This, combined with the use of the LeanTween library for animation, results in a seamless and visually appealing UI experience. However, the challenge of accommodating the UI to the varying screen sizes of mobile devices arose. To tackle this, I selected two extreme device sizes (a 21:9 phone and a 4:3 tablet) and optimized the UI to work effectively on each screen, ensuring a consistent and enjoyable experience across all devices. </p>
          <h4 className="content-text">Physics Implementation</h4>
          <p className="content-text">CricketWay showcases a fundamental application of projectile physics in both hitting and throwing the ball. To complement the hitting system, a separate simulation of the thrown ball is carried out to accurately predict where it will strike the batsman. This results in a seamless and realistic in-game experience.</p>
          `
        },
        {
          title: "Notable",
          markdown: `<h2 className="content-text">Leaderboard</h2>
          <img className="image-singular-full" 
          src="https://i.ibb.co/RTN1XFt/Leaderboard.png" draggable="false">
          </img>
          <p className="content-text">The game features global leaderboard which player may submit the score to if it's within top 10. The leaderboard is updated once the player submits the score or every 30 seconds.</p>
          <h2 className="content-text">Tutorial</h2>
          <img className="image-singular-full" 
          src="https://i.ibb.co/cL9vt1n/Tutorial.png" draggable="false">
          </img>
          <p className="content-text">In CricketWay, the player is shortly introduced to home menu items as well as objectives when entered the game.</p>
          <iframe className="videoPlayer" src="https://www.youtube.com/embed/ZsUsMEvdvP4?si=0D9FVwLF5rBZC8kt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className="content-text"> The game was created as a prototype for a private media company in Denmark. It's available for download on <a className="content-text" href="https://apps.apple.com/dk/developer/sportcc-aps/id1449128680">App Store</a>
           and <a className="content-text" href="https://play.google.com/store/apps/details?id=com.sportcc.CricketWay">Google Play</a>.</p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "VR CNC Simulator",
      width: 8,
      height: 2.5, 
      cover_image:"https://i.ibb.co/R0QbnWP/VR-CNC-Simulator.png",
      content: [
        {
          title: "Description",
          markdown: `<p className="content-text">VR CNC Simulator teaches students how to programme and operate CNC machines, as well as do technical calculations and sketches in a CAD system. The cost of such education equipment might be incredibly high. Learnmark must not only order multiple expensive milling machines, but there are also ongoing costs such as maintenance and repair. The application was implemented using Unity and SteamVR. </p>
          <img className="image-singular-full" 
          src="https://i.ibb.co/0hjjH4b/CNC-Machine-Setup.jpg" draggable="false">
          </img>
          <img className="image-singular-full" 
          src="https://i.ibb.co/MVd3tLz/CNC-Machine-Cutting.jpg" draggable="false">
          </img>
          `
          
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text"> Tutorial UI Design</h3>
          <p className="content-text"> During development of the project I had responsibilties of implementing user interface for the tutorial (which is the main feature of the application). I decided to go for an interactable tablet from which student is able to access tutorial direction through a text or a video, as well as control tutorial highlighting feature.</p>
          <img className="image-singular-full" 
          src="https://i.ibb.co/dc2QyCV/tablet.png" draggable="false">
          <h3 className="content-text"> Sound Design </h3>
          <p className="content-text"> Sound design in VR is essentially to fully immerse the user. For this project I was responsible for sourcing CC0 sounds that would fit the theme of a workshop. I covered interactions with the tutorial tablet, basic interactions with interactable items machine audio, as well as created and voiced the entire tutorial system. </p>`
        },
        {
          title: "Gameplay",
          markdown: ` <iframe className="videoPlayer" src="https://www.youtube.com/embed/jNduP2Xm4_o?si=wnuhpN2rtxj0b9yX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className="content-text"> This project was designed and developed for Learnmark Horsens, Denmark </p>
          <div className="filler-div"></div>`
        }
      ]
    },
   
    {
      title: "Damn Gremlins!",
      width: 4,
      height: 2.5, 
      cover_image:"https://i.ibb.co/sw3vfHq/Screenshot-1.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-small" 
          src="https://i.ibb.co/kyGZV3d/thumbnail.jpg" draggable="false">
        </img>
        <p className="content-text"> Damn Gremlins is a 1 minute game experience built using Unity and Microsoft Kinect. The player is prompted to save the Christmas from Gremlins who are on their way to steal Christmas presents!The game is very simple in its concept: User performs throwing gesture which launches the snowball at the direction of the gesture, in attempt to hit the Gremlins. The game has a limited amount of presents to steal and set amount of Gremlins which will attempt to steal the presents. The game is meant to be placed in shopping malls and similar locations. </p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<div className="row">
          <img className="column-image" 
          src="https://i.ibb.co/GvpCJZd/Screenshot-5.png" draggable="false">
          </img>
          <img className="column-image" 
          src="https://i.ibb.co/hHZYkjp/Screenshot-4.png" draggable="false">
          </img>
          </div>
          <h3 className="content-text"> Mechanics </h3>
          <p className="content-text"> Game consists of several mechanics: custom throwing gesture which is triggered when the right or left hand is moved forward from behind the head. Gremlins are limited in numbers and only choose to steal from houses that are currently not being stolen from.</p>`
        }
        ,
        {
          title: "Gameplay",
          markdown: ` <iframe className="videoPlayer" src="https://www.youtube.com/embed/5M0Aj-c_e2I?si=tUT8298hz2nOgICl" allowfullscreen></iframe>
          <p className="content-text"> This small game was created as a promotional material for a Shopping Mall in Aarhus, Denmark</p>`
        }
      ]
    },
    
    {
      title: "Sounds Like Roskilde",
      width: 3,
      height: 3, 
      cover_image:"https://i.ibb.co/VQ3BgtX/Screenshot-20230210-094732.jpg",
      content: [
        {
          title: "Description",
          markdown: `<div className="row">
          <img className="column-image" 
          src="https://i.ibb.co/H4wM0rM/Sounds-Like-Roskilde-1.jpg" draggable="false">
          </img>
          <img className="column-image" 
          src="https://i.ibb.co/N22QwPZ/Sounds-Like-Roskilde-2.gif" draggable="false">
          </img>
          </div>
        <p className="content-text">The summer of 2020 proved to be a difficult time for event planners, including the Roskilde Festival. However, this did not stop the festival from continuing to spread its spirit. The initiative, "Sounds like Roskilde," encourages participants to bring the festival experience to their own homes and backyards. Festival-goers can still purchase tickets, with all profits going towards charitable projects. Roskilde Festival also wanted to offer a mobile AR application, allowing users to enjoy the concerts in the comfort of their own homes. "Sounds like Roskilde" features a collection of green-screen recordings, expertly chrome-keyed and displayed as videos in Augmented Reality, bringing the festival to life in a unique and innovative way.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">Content data management</h3>
          <p className="content-text">In this project, my central role was to design and implement a robust downloading system for new concerts. The crucial elements of the application required the ability to remotely modify the available artists and make them accessible to users. To achieve this, I utilized the Firebase SDK for Unity, which enabled me to efficiently manage this aspect of the application. The supervisor of the application was also empowered to manage the content through a web-based content management application, allowing them to upload text, images, and videos to create new artists, delete or modify existing ones, and ensure that the application roster was always up-to-date. </p>
          <h3 className="content-text">In-Game GUI </h3>
          <div className="row">
          <img className="column-image" 
          src="https://i.ibb.co/Z6W4kjS/Screenshot-20230210-200827.jpg" draggable="false">
          </img>
          <img className="column-image" 
          src="https://i.ibb.co/rG5nrqY/Screenshot-20230210-094742.jpg" draggable="false">
          </img>
          </div>
          <p className="content-text"> UI design of the application was supplied by a partner. I took the responsibility of implementing it as it was described in AdobeXD document and applied further changes as per client request.</p>
          `
        },
        {
          title: "Notable",
          markdown: `<h3 className="content-text">Content management system</h3>
          <p className="content-text"> Sounds like Roskilde featured a simple management system to add, update and delete artists from the application roster. It was built using Unity webGL build which in hindsight is not the best approach for a system like this. Live and learn.</p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "VR Electrician",
      width: 9,
      height: 3, 
      cover_image:"https://i.ibb.co/5Rsw5s8/Electrician-Simulator.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/FY7n5Nt/Screenshot-2.png" draggable="false">
        </img>
        <p className="content-text"> VR Electrician, an innovative simulator developed with Unity and SteamVR, presents a sandbox environment for electrical wiring design and circuit simulation. This provides students with a secure platform to hone their electrical installation skills in a controlled environment.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">Cable-drawing system </h3>
          <p className="content-text"> The primary objective of the VR Electrician Simulator was to instill proper cable installation techniques. As electricians, it is imperative to neatly organize cables for ease of maintenance in the future. My role in this project involved the creation of a cable drawing feature that adheres to these guidelines. I utilized player controller positions to project onto the drawable space, enabling me to validate the positioning and control the drawing system accordingly. Additionally, the drawing system provided context-aware suggestions for cable placement. This presented a unique challenge when players needed to draw cables in tight spaces, such as corners between walls. To overcome this, I employed the SplineMesh method to render cables in real-time, optimizing it to meet the demanding 90 FPS requirement for comfortable VR headset use. </p>
          <img className="image-singular-full" 
          src="https://i.ibb.co/ryWvq8V/Screenshot-1.png" draggable="false">
          </img>
          <h3 className="content-text">Environment setup </h3>
          <p className="content-text"> In this project, I also focused on establishing a realistic environment, specifically by selecting appropriate textures and materials that were both free to use and conveyed the atmosphere expected of an electrician's workspace. My goal was to create a setting that accurately represents what students specializing in electricity would expect to encounter in their field. </p>`
        }
        ,
        {
          title: "Gameplay",
          markdown: `<iframe className="videoPlayer" src="https://www.youtube.com/embed/8bctIKFLwVM?si=nVwXauIATfPmhjTj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className="content-text"> This project was designed and developed for Learnmark Horsens, Denmark </p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "Landsoldaten",
      width: 5,
      height: 4, 
      cover_image:"https://i.ibb.co/pxtPNdv/Landsoldat-Cover.jpg",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full-small" 
          src="https://i.ibb.co/48BpGbd/Landsoldaten-Gameplay-1.png" draggable="false">
        </img>
        <p className="content-text">Landsoldaten is a Pokemon GO inspired, GPS-based educational game. A museum in Fredericia, Denmark requested to develop a free mobile application in which a user would go exploring Fredericia Vold by collecting historical artifacts, combined into stories prepared by the representatives of the museum with a main story teller of the game being “Landsoldat”, the historical statue of Fredericia representing 1850s era. The game was intended to be used by tourists travelling through Fredericia and learning about its historical roots as well as students on school trips to the museum. The player's goal is to complete the mission line prepared by the museum, as player progresses throughout the game, he or she is awarded with interesting videos and images from the era.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">In-Game GUI </h3>
          <img className="image-singular-full-small" 
          src="https://i.ibb.co/WHzVFRR/Landsoldaten-UI-2.png" draggable="false">
          </img>
          <p className="content-text"> In this game, the user interface is designed with a stack-based approach, allowing players to navigate through the various game screens and always return to the main game screen. This innovative approach helps to mitigate common challenges related to UI navigation and animation. The UI comprises of the Main Screen, Journal, Map, and Player Settings Screen, each offering a unique and immersive gaming experience. The animation was crafted using the LeanTween library, adding an extra layer of polish and contributing to the overall success of the project.</p>
          <h3 className="content-text">Implementing data strucutre </h3>
          <p className="content-text"> This project required a well-planned out data structure to fulfill its objectives, including the pivotal components of Epoch, Mission, Task, Artifact, and Reward data. All of these were organized and stored in Firebase, enabling remote updates to the Epoch mission-line, providing opportunities for new content to be added for the players to enjoy. The data structure entailed multimedia assets, such as videos, images, text, and 3D model files, which were downloaded via the downloadable content system. This setup allowed for a seamless and dynamic play experience.</p>
          <h3 className="content-text">Downloadable content system </h3>
          <p className="content-text"> To support the data structure, I created a file fetching system. Most notabely, it runs in the background, supports file version upgrades ensuring that only the necessery information was downloaded and kept on the user's phone. </p>`
        }
        ,
        {
          title: "Find and Play",
          markdown: `<p className="content-text"> This game was designed and developed for Fredericia's municipality in Denmark, It's available for download on <a className="content-text" href="https://apps.apple.com/us/app/landsoldaten/id1567022016">App Store</a> and
           <a className="content-text" href="https://play.google.com/store/apps/details?id=com.pielab.soldier&hl=da&gl=US">Google Play</a> </p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "Untitled Frog Game",
      width: 7,
      height: 4, 
      cover_image:"https://i.ibb.co/8MfK17z/Screenshot-1.png",
      content: [
        {
          title: "Description",
          markdown: `<p className="content-text">As the name suggests, Untitled Frog Game is an unfinished game project that I am currently working on in my spare time. I am deeply inspired by games such as Witcher 3, God of War, frogs and Action-RPG game genre with open-world roaming capabilities. At this very moment I'm working on structural game mechanics and storyline.</p>
          `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text"> Game Concept </h3>
          <p className="content-text"> The game will contain a storyline that takes roughly 10-15 hours to complete, The story will be split into several major chapters which will take place in different areas of the world map. To be continued...</p>
          <h3 className="content-text"> Gameplay </h3>
          <p className="content-text"> Untilted Frog Game will consist of immersive combat system and challenging AI through regular enemies, bosses and puzzles. To be continued...</p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "Digital Advent",
      width: 12,
      height: 4, 
      cover_image:"https://i.ibb.co/ZhNrw4y/Jule-Kalendar.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/PYbg79D/Digital-Advent-2.png" draggable="false">
        </img>
        <p className="content-text"> Digital Advent, as the name suggests is an interactive advent calendar implementation online. It's designed to educational bring fun to the countdown to Christmas. Behind each window, kids will find a book containing videos, pictures and meaningful, Christmas-related questions that they are prompted to discuss further in the classroom. The application consists of 2 web-based solutions. 1. Advent calendar 2. Management solution. Users are invited to the calendar through an url, which is sent to the email. Advent calendar solution focuses on presentation and nice visual effects, to accomodate the Christmas mood. </p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/Nrpmnyg/Digital-Advent-3.png" draggable="false">
          </img>
          <h3 className="content-text"> Project data structure implementation</h3>
          <p className="content-text"> During the inception phase of the project, I took the project requirements and implemented a data strucutre based on it. Most notable requirements were custom calendar creation, user managament and shared user session persistance. Advent period takes 24 days before Christmas, therefore each calendar consists of 24 windows which contain data for content, its size and position within the calendar. Keeping in mind project scale and number of users, I chose to use Firebase Firestore as a database solution, which helped me greatly reduce the amount of development time spent on the project.</p>
          <h3 className="content-text"> Advent Calendar implementation </h3>
          <p className="content-text"> I chose reactJS as a framework behind the solution. Huge focus of the application was to create a structure that would work well with the content that needs to be presented to the children. Advent calendar consists of a background image, 24 windows filled out with customizable data, soundtrack and SFX, particleFX for the cursor and many animations to create a nice presenation to the users. Users access the calendar based on ID which is generated when the user is created in the managament solution. Each user holds a reference to what calendar was assigned to it and this way many people can act as the same user and keep the shared persistance. Moreover, the accessed user ID is stored in the cookies for 30 days so if the same computer is used to access multiple users, the authentification only happens once per user.</p>
          <h3 className="content-text"> Management solution implementation </h3>
          <p className="content-text"> I chose reactJS as a framework behind the solution. To reduce the amount of work required to implement the project, I found a template that could work well with managing the digital calendars. Management solution contains features to create and edit calendars, create and edit classrooms(users), assign new calendars and invite real people to get access to a classroom. </p>
          <div className="filler-div"></div>`
          
        }
      ]
    },
    {
      title: "Kinect VFX",
      width: 6,
      height: 4, 
      cover_image:"https://i.ibb.co/z2mhnjV/Screenshot-1.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/z2mhnjV/Screenshot-1.png" draggable="false">
          </img>
        <p className="content-text">This VFX sample was built for a festival in Denmark, as a showcase material for a company they I am working for. Implemented using Unity, VFX Graph and Microsoft Kinect.</p>
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">Implementation</h3>
          <p className="content-text">This VFX sample was built out of a template available at github.com/keijiro. I included Microsoft Kinect tracking implementation.</p>
          <div className="filler-div"></div>`
        }
      ]
    },
    {
      title: "Interactive Portraits",
      width: 6,
      height: 4, 
      cover_image:"https://i.ibb.co/zZRGHdy/Frederik-VII-3.png",
      content: [
        {
          title: "Description",
          markdown: `<img className="image-singular-full" 
          src="https://i.ibb.co/qd34pJ3/Frederik-VII-2.jpg" draggable="false">
        </img>
        Interactive Portraits is a solution built for museums to bring historical figures alive using their pictures and paintings. The whole solution consists of a customizable reactJS-based webplayer which allows it to run on any platform (I used raspberry Pi 4). It is coupled with a touchscreen display and a speaker all contained within a wooden frame.
        `
        },
        {
          title: "Responsibilities",
          markdown: `<h3 className="content-text">Researching animation tools </h3>
          <p className="content-text">In this project I had the responsibility of finding the right tools and techniques to create multiple videos fast and cheap. I settled with First Order Animation based facial animation application to create the content. The application was intially built with Unity, webGL build, due to performance issues I recreated the project using reactJS.</p>
          <h3 className="content-text"> Webplayer implementation</h3>
          <div className="row">
          <img className="column-image" 
          src="https://i.ibb.co/wQmj9dv/Frederik-VII-4.png" draggable="false">
          </img>
          <iframe className="column-image" src="https://www.youtube.com/embed/pVJeQmB0dKg" title="Interactive Display (3/3)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <p className="content-text"> The application was created using reactJS. It includes a video and subtitle player as well as loading features to fetch remote videos. I implemented basic animations using css and GSAP js libraries.</p>
          <h4 className="content-text"> Content management</h4>
          <p className="content-text"> Interactive portraits contain management tool implementation to control the content on the webplayer, user can remotely add new languages, create and edit subtitles, change videos and more.</p>
          <div className="filler-div"></div>`
        }
      ]
    }
  ]);

  const onFullscreen = (title) => {
    const project = projects.find((x) => x.title === title);
    fullscreen(true, project.content, project.title);
  };
  return (
    <section id="works">
    {window.innerWidth / window.innerHeight >= 15 / 9 ? (
        <Container component="main"
        className={classes.main}>
          <h1 className="contact_msg">
            My work
          </h1>
      <Grid container spacing={3} columns={{ xs: 1, sm: 2, md: 3 }}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={project.width}
          style={{
            height: `${project.height*200}px`, // Use a dynamic height value
          }} key={index}>
            <Paper
              className={`${classes.projectItem} ${classes.projectItemHover}`} // Apply hover effect
              onClick={() => onFullscreen(project.title)}
            >
              <img
                src={project.cover_image}
                alt={project.title}
                className={classes.projectImage}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
      </Container>
      ) : (
        <Container component="main"
        className={classes.main}>
          <h1 className="my work">
            Some of my work
          </h1>
          <Grid container spacing={3} columns={{ xs: 1, sm: 2, md: 3 }}>
            {projects.map((project, index) => (
              <Grid item xs={12} sm={6} md={project.width}
              style={{
                height: `${project.height*100}px`, // Use a dynamic height value
              }} key={index}>
                <Paper
                  className={classes.projectItem}
                  onClick={() => onFullscreen(project.title)}
                >
                  <img
                    src={project.cover_image}
                    alt={project.title}
                    className={classes.projectImage}
                  />
                </Paper>
              </Grid>
            ))}
            </Grid>
        </Container>
      )}
    </section>
  );
};
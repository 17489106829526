import React, { useEffect, useContext } from 'react';
import { useState, useRef} from "react";
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { Resume } from '../components/resume/Resume';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { SideNavbar } from '../components/nav/SideNavbar';
import { Works } from '../components/works/Works';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import { Tooltip, IconButton, Zoom } from "@material-ui/core";
import { TextDecrypt } from "../components/content/TextDecrypt";
import Slide from '@material-ui/core/Slide';
import '../components/works/Works.css'
import { Close} from "@material-ui/icons";
import { ThemeContext } from "../components/theme/ThemeProvider";

import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import { Container,Typography } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

export const Home = () => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles();
  const background = useRef(null);
  const rgbBackground = theme === "light" ? "#fafafa" : "#111111";
  const [elements, setElements] = useState([
    {
      title: "Description",
      markdown: `<img className="image-singular-full" 
      src="https://i.ibb.co/7WVd1Dr/Cricket-Banner-V3.png" draggable="false">
    </img>
    CricketWay is a casual mobile cricket game that is currently in development for iOS and Android platforms. The game is built with Unity and offers an enjoyable and accessible experience for cricket fans of all levels. In  CricketWay, you play as a batsman for a variety of national teams and compete in both Single and Tournament game modes. The timing hit system adds a fun and competitive element to the gameplay, while the leaderboard allows players to compete with others and see where they rank. Players can also share their experiences and achievements on social media.The opposing team AI provides a balanced and challenging experience, making every game unique.`
    },
    {
      title: "Responsibilities",
      markdown: `<h2 className="content-text">Game Design and Development</h2>
      <img className="image-singular-full" 
      src="https://i.ibb.co/dtk8H3n/Gameplay-1.png" draggable="false">
      </img>
      <h3 className="content-text"> Game Concept </h3>
      <p className="content-text">CricketWay is a casual, mobile cricket game designed for cricket fans of all ages. The game is easy to pick up and play but challenging enough to keep players engaged. Genre of the game is sports simulation.</p>
      <h3 className="content-text"> Gameplay </h3>
      <h4 className="content-text"> Objectives </h4>
      <p className="content-text">The player's goal is to score as many runs as possible in a single game mode. During tournament, player is assigned a target of runs he or she needs to make within a limit of balls and wickets which are randomized.</p>
      <h4 className="content-text">In-Game GUI </h4>
      <img className="image-singular-full" 
      src="https://i.ibb.co/sgfNpq9/Physical-UI-2.png" draggable="false">
      </img>
      <p className="content-text"> To make it stand out of the crowd, the game featues physical UI inspired by other games. This ultimately creates a fluid UI that feels good to use and is nice to look at.</p>
      <h3 className="content-text">Mechanics<h3>
      <h4 className="content-text">Rules</h4>
      <p className="content-text">The game follows standard cricket rules, including overs, runs, wickets, and boundaries. Some of the rules are simplified to support choices made by the customer</p>
      <h4 className="content-text">Physics</h4>
      <p className="content-text">CricketWay features basic projectile physics for hitting and throwing the ball. To work well with the hitting system, the ball thrown is first simulated in a seperate scene to find out where it will hit the batsman</p>`
    },
    {
      title: "Notable",
      markdown: `<h2 className="content-text">Leaderboard</h2>
      <img className="image-singular-full" 
      src="https://i.ibb.co/RTN1XFt/Leaderboard.png" draggable="false">
      </img>
      <p className="content-text">The game features global leaderboard which player may submit the score to if it's within top 10.</p>
      <h2 className="content-text">Tutorial</h2>
      <img className="image-singular-full" 
      src="https://i.ibb.co/cL9vt1n/Tutorial.png" draggable="false">
      </img>
      <p className="content-text">In CricketWay, the player is shortly introduced to home menu items as well as objectives when entered the game.</p>`
    }
  ]);
  const [selectedProjectTitle,setSelectedProjectTitle] = useState();
  const [isFullscreen,setIsFullscreen] = useState(false);
  const onFullScreenToggle = (value, content, title) =>{
    setIsFullscreen(value);
    setActiveSection(0);
    if(value)
    {
      setElements(content);
      setSelectedProjectTitle(title);
    }
  }
  useEffect(()=>{
    if(background.current)
    {
      background.current.style.background = rgbBackground;
    }
  })

  const [activeSection, setActiveSection] = useState(0);
  const [sectionOffsets, setSectionOffsets] = useState([]);
  const myRef = useRef(null);

  useEffect(() => {
    console.log("Use effect");
    const handleScroll = () => {
      console.log("Handle scroll");
      const scrollTop = myRef.current.scrollTop;
      console.log("current scroll top: " + myRef.current.scrollTop);
      let activeIndex = 0;
      for (let i = 0; i < sectionOffsets.length; i++) {
        if (scrollTop > sectionOffsets[i]-5) {
          activeIndex = i;
        } else {
          break;
        }
      }
      setActiveSection(activeIndex);
    };
    if(myRef.current==null)
    return;
    myRef.current.addEventListener('scroll', handleScroll);
    return () => myRef.current.removeEventListener('scroll', handleScroll);
  }, [sectionOffsets]);

  useEffect(() => {
    const handleNavClick = (index) => {
      const sectionRef = myRef.current.querySelector(`#section-${index}`);
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const offsets = elements.map((item, index) => {
      const sectionRef = document.querySelector(`#section-${index}`);
      return sectionRef ? sectionRef.offsetTop : 0;
    });
    console.log("Sections offsets: " + offsets);
    setSectionOffsets(offsets);

    const navItems = document.querySelectorAll('.project-fullscreen-navbar li');
    navItems.forEach((item, index) => {
      item.addEventListener('click', () => handleNavClick(index));
    });
    return () => {
      navItems.forEach((item, index) => {
        item.removeEventListener('click', () => handleNavClick(index));
      });
    };
  }, [elements])

  return (
    <>
      <div className={classes.root} id="home">
        
        
        <DisplacementSphere />
        
      </div>
      
      <div className="fixed-mobile-header-parent">
      
          <div className = {`fixed-mobile-header${isFullscreen ? "-maximized" : ""}`}>
         
            <Content/>
            <Works fullscreen={onFullScreenToggle}/>
            <About />
            <Contact />
          </div>
          
          
        </div>
        <SideNavbar />
            <LogoLink />
            <ThemeToggle />
            <Hidden smDown>
                <SocialIcons />
              </Hidden>
              <Hidden mdUp>
                <SpeedDials />
              </Hidden>
              <Resume />
              
        <Slide direction="up" in={isFullscreen} mountOnEnter unmountOnExit>
                  <div  ref={background} className="project-fullscreen">
                      <div className='project-fullscreen-title'>
                        <Typography component='h2' variant="h2">
                          <TextDecrypt text={selectedProjectTitle} />
                        </Typography>
                      </div>
                      <ul className="project-fullscreen-navbar">
        {elements.map((item, index) => (
          <li
            key={item.title}
            className={activeSection === index ? 'active' : null}
          >
            <Typography component="h3" variant="h5">
              <TextDecrypt text={item.title} />
            </Typography>
          </li>
        ))}
      </ul>
      <div className="project-fullscreen-content-parent">
        <div ref={myRef} className="project-fullscreen-content">
          <div className="sections">
            {elements.map((item, index) => (
              <div key={item.title} id={`section-${index}`} className="sec">
                <div className='filler-div-small'></div>
                <h1>{item.title}</h1>
                <div className="markdown-content">
                  <ReactMarkdown rehypePlugins={rehypeRaw} children={item.markdown} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
                      <div 
                      className="exit-fullscreen" 
                      >
                        <Tooltip
                        title={"Exit"}
                        placement="left"
                        TransitionComponent={Zoom}>
                        <IconButton
                          color="inherit"
                          onClick={()=>onFullScreenToggle(false)}
                          aria-label={"Exit"}
                          className={classes.iconButton}>
                          <Close className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      </div>
                  </div>
                  
            </Slide>
        
    </>
  );
};
